<template>
  <div>
    <div>
      <div style="text-align: center; margin-top: 10px">
        <div style="margin-top: 20px; box-shadow: 0 1px 6px rgb(0 0 0 / 6%)">
          <div
            class="deptMainTop"
            style="box-shadow: 0 1px 6px rgb(0 0 0 / 6%)"
          >
            <div class="box-item commonBorder">
              <span>
                <img src="../../assets/观看文章数.png" />
              </span>

              <span class="descColor"> 观看文章数 </span>

              <span class="numColor">
                {{ deptReadInfo["perCount"] || 0 }}
              </span>
            </div>

            <div class="box-item commonBorder">
              <span>
                <img src="../../assets/文章阅读量.png" />
              </span>

              <span class="descColor"> 文章阅读量 </span>
              <span class="numColor">
                {{ deptReadInfo["count"] }}
              </span>
            </div>

            <div class="box-item commonBorder">
              <span>
                <img src="../../assets/文章点赞量.png" />
              </span>
              <span class="descColor"> 文章点赞量 </span>

              <span class="numColor">
                {{ deptReadInfo["like"] }}
              </span>
            </div>

            <div class="box-item commonBorder">
              <span>
                <img src="../../assets/文章评论量.png" />
              </span>

              <span class="descColor"> 文章评论量 </span>

              <span class="numColor">
                {{ deptReadInfo["comment"] }}
              </span>
            </div>

            <div class="box-item commonBorder">
              <span>
                <img src="../../assets/文章分享量.png" />
              </span>

              <span class="descColor"> 文章分享 </span>

              <span class="numColor">
                {{ deptReadInfo["share"] }}
              </span>
            </div>

            <div v-if="deptReadInfo['totalNum']" class="box-item commonBorder">
              <span>
                <img src="../../assets/平台进入次数.png" />
              </span>

              <span class="descColor"> 平台进入次数 </span>

              <span class="numColor">
                {{ deptReadInfo["totalNum"] }}
              </span>
            </div>

            <div v-if="deptReadInfo['allTime_s']" class="box-item commonBorder">
              <span>
                <img src="../../assets/平台阅读时长.png" />
              </span>

              <span class="descColor">平台阅读时长 </span>

              <span class="numColor">
                {{ deptReadInfo["allTime_s"] }}(分)
              </span>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-top: 20px; box-shadow: 0 1px 6px rgb(0 0 0 / 6%)">
        <div style="box-shadow: 0 1px 6px rgb(0 0 0 / 6%)">
          <div class="deptMainTop">
            <div class="box-item commonBorder">
              <span>
                <span>
                  <img src="../../assets/阅读积分.png" />
                </span>
              </span>

              <span class="descColor"> 阅读积分 </span>
              <span class="numColor">
                {{ deptReadInfo["clickIntegral"] }}
              </span>
            </div>

            <div class="box-item commonBorder">
              <span>
                <span>
                  <img src="../../assets/点赞总积分.png" />
                </span>
              </span>
              <span class="descColor"> 点赞总积分 </span>

              <span class="numColor">
                {{ deptReadInfo["likeIntegral"] }}
              </span>
            </div>

            <div class="box-item commonBorder">
              <span>
                <img src="../../assets/签到总积分.png" />
              </span>

              <span class="descColor"> 签到总积分 </span>

              <span class="numColor">
                {{ deptReadInfo["signIntegral"] }}
              </span>
            </div>

            <div class="box-item commonBorder">
              <span>
                <img src="../../assets/分享总积分.png" />
              </span>

              <span class="descColor"> 分享总积分 </span>

              <span class="numColor">
                {{ deptReadInfo["shareIntegral"] }}
              </span>

              <span> </span>
            </div>
            <div class="box-item commonBorder">
              <span>
                <img src="../../assets/评论总积分.png" />
              </span>

              <span class="descColor"> 评论总积分 </span>
              <span class="numColor">
                {{ deptReadInfo["commentIntegral"] }}
              </span>
            </div>
            <div class="box-item commonBorder">
              <span>
                <img src="../../assets/阅读时长总积分.png" />
              </span>

              <span class="descColor"> 阅读时长总积分 </span>
              <span class="numColor">
                {{ deptReadInfo["readingTimeIntegral"] }}
              </span>
            </div>
            <div class="box-item commonBorder">
              <span>
                <img src="../../assets/视频观看积分.png" />
              </span>

              <span class="descColor"> 视频观看积分 </span>
              <span class="numColor">
                {{ deptReadInfo["readingVideoIntegral"] }}
              </span>
            </div>
            <div class="box-item commonBorder">
              <span>
                <img src="../../assets/视频阅读总积分.png" />
              </span>

              <span class="descColor"> 视频阅读总积分 </span>
              <span class="numColor">
                {{ deptReadInfo["readingVideoTimeCountIntegral"] }}
              </span>
            </div>
          </div>

          <div
            v-if="deptReadInfo['integralAll']"
            style="
              text-align: right;
              padding: 10px 20px;
              box-shadow: 0 1px 6px rgb(0 0 0 / 6%);
            "
          >
            <svg
              style="display: inline-block"
              t="1648469322730"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              p-id="24311"
              width="20"
              height="20"
            >
              <path
                d="M810.666667 1002.666667H234.666667c-70.570667 0-128-57.429333-128-128 0-27.477333 9.045333-54.741333 25.557333-76.757334L346.666667 512 132.266667 226.176A128.170667 128.170667 0 0 1 234.666667 21.333333H810.666667c58.794667 0 106.666667 47.829333 106.666666 106.666667v128a64 64 0 1 1-128 0V149.333333h-554.666666l243.2 324.266667c17.066667 22.741333 17.066667 54.101333 0 76.842667l-243.2 324.266666h554.666666V768a64 64 0 1 1 128 0v128c0 58.837333-47.872 106.666667-106.666666 106.666667z"
                fill="#00b2bd"
                p-id="24312"
              ></path>
              <path
                d="M341.290667 1002.666667A64.042667 64.042667 0 0 1 290.133333 900.224l291.2-388.266667L290.133333 123.733333a64 64 0 0 1 102.4-76.8l320 426.666667c17.066667 22.784 17.066667 54.058667 0 76.842667l-320 426.666666a64 64 0 0 1-51.242666 25.557334z"
                fill="#00b2bd"
                p-id="24313"
              ></path>
            </svg>
            <span style="color: #00b2bd">
              总积分<span style="font-weight: bold">{{
                deptReadInfo["integralAll"]
              }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// TODO 报告生成中
// @ is an alias to /src
// https://wx-stats.gtrmt.cn/?path=deptReport&deptId=3005&e=1673107200000&s=1672588800000#/deptReport
// https://wx-stats.gtrmt.cn/?path=deptReport&s=1672588800000&e=1673107200000/#/deptReport

export default {
  name: "commonPic",
  components: {},

  data() {
    return {};
  },
  props: {
    deptReadInfo: {
      type: Object,
      default: () => {
        return {
          name: "",
          Positive: 0,
          Neutral: 0,
          Negative: 0,
          count: 0,
          like: 0,
          share: 0,
          comment: 0,
          commentView: 0,
          count_lj: 0,
          count_zd: 0,
          like_lj: 0,
          like_zd: 0,
          share_lj: 0,
          share_zd: 0,
          comment_lj: 0,
          comment_zd: 0,
          perCount: 0,
          totalNum: 0,
          ljNum: 0,
          ljTime_s: 0,
          zdNum: 0,
          zdTime_s: 0,
          jzNum: 0,
          jzTime_s: 0,
          signIntegral: 0,
          clickIntegral: 0,
          readingTimeIntegral: 0,
          readingVideoIntegral: 0,
          readingVideoTimeCountIntegral: 0,
          likeIntegral: 0,
          shareIntegral: 0,
          commentIntegral: 0,
          integralAll: 0,
          wxRun: 0,
        };
      },
    },
  },

  methods: {},

  async created() {},
};
</script>

<style scoped>
img {
  width: 2em;
  height: 2em;
}
.userReport {
  padding: 10px;
  text-align: left;
  color: #55627f;
  font-weight: bold;
}
.userBaseInfo {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  padding: 10px;
  text-align: left;
}
.userBaseInfoItem {
  flex: 1;
  width: 50%;
  min-width: 50%;
  max-width: 50%;
  text-align: left;
  padding: 8px 0;
  font-weight: bold;
}
.userBaseInfoItem .descColor {
  color: #9498a2;
}
.userBaseInfoItem .valColor {
  color: #626877;
}

.deptMainTop {
  text-align: center;
  /* color: white; */
  padding: 15px 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
  grid-template-rows: repeat(1, 1fr);
  text-align: center;
  align-items: center;
  justify-content: center;
}

.deptMainTop .box-item {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  font-size: 11px;
  position: relative;
  font-weight: bold;
}
.deptMainTop .box-item span {
  flex: 1;
  color: #b57464;
  font-size: 16px;
}

.deptMainTop .box-item .numColr,
.numColor {
  font-size: 16px;
  font-weight: bold;
  color: #866060;
}
.deptMainTop .box-item .descColor,
.descColor {
  color: #bbb;
  overflow: hidden;
  font-size: 10px;
}

.labelSty {
  display: inline-block;
  color: #de6e6a;
  border: 1px solid rgb(134 96 96 / 23%);
  padding: 1px 3px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  margin: 0 3px 0 0;
}
.labelTitle {
  display: inline-block;
  color: #866060;
  padding: 3px 4px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
}
</style>
